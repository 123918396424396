import styles from "./card.module.css"

export default function SmallCard(props) { return (
	<div className={styles.small}>
		<div className={styles.outer}>
			<div className={styles.inner}>
				{/* TODO: Import as SVG */}
				<props.icon className={styles.icon}/>
			</div>
			<h2 className={styles.heading}>{props.title}</h2>
			<div className={styles.subheading}>{props.children}</div>
		</div>
	</div>
)}