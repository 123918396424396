"use client";

import React from "react";
import Link from "next/link";
import staticImageLoader from "~/loaders/static-image-loader";

// import dynamic from "next/dynamic";
// const Button = dynamic(() => import('@nextui-org/button').then(m => m.Button), { loading: () => <button>Ta Kontakt!</button> })
import { Button } from "@nextui-org/button";

import HeroImage from "./girl.png";
import Image from "next/image";

import style from "./header.module.css";

export default function Header() {
    return (
        <header className={style.header} style={{ height:'40rem', backgroundColor:'#d10cb7' }}>
            <div className={style.content}>
                <div className={style.text}>
                    <div className={style.textbox} style={{ fontDisplay: 'swap', marginTop:'1.5rem', textAlign:'center' }}>
                        <h1>Velkommen til <span>Ascendi</span></h1>
                        <p>
                            Vi tilbyr heldøgns bo- og omsorgstjenester til de som har behov for det i en støttende og trygg atmosfære. 
                            Vårt team av fagpersoner, miljøarbeidere og faglige nettverk er opptatt av å gi helse- og 
                            omsorgstjenester. Pårørende er velkomne til overnatting etter avtale.
                        </p>
                        <Link href="kontakt" className={style.btn} style={{ margin:'0 auto', alignContent:'center', width:'100%', padding:'2rem' }}>
                            <Button 
                                className="font-bold text-lg"
                                style={{ width:"100%" }}
                                color="warning" 
                                radius="md"
                            >
                                Ta Kontakt!
                            </Button>
                        </Link>
                    </div>
                </div>
                <div className={style.img + " select-none"}>
                    {/* 35 rem */}
                    <div style={{ position:'relative', width:'100%', height:'100%', margin:'0 auto' }} className="flex flex-column justify-center" media="screen and (min-width: 900px)">
                        <Image
                            style={{ objectFit: "contain", paddingRight:'3rem', userSelect:"none" }}
                            src={HeroImage}
                            width={HeroImage.width}
                            height={HeroImage.height}
                            loader={staticImageLoader}
                            sizes="33vw"
                            alt=""
                            // priority
                        />
                    </div>
                </div>
            </div>
        </header>
    )
}