"use client"

import staticImageLoader from "~/loaders/static-image-loader";
import Image from "next/image";
import styles from "./card.module.css";

export default function LargeCard({ img, alt, title, subtitle, children }) { 
    return (
        <div className={styles.large}>
            <Image
                loader={staticImageLoader}
                alt={alt || ""}
                className={styles.img}
                src={img.src}
                height={400}
                width={400}
                sizes="(max-width: 768px) 100vw, 33vw"
            />
            <div className={`${styles.fill} ${styles.gradient}`}/>
            <div className={styles.fill}>
                <div className={styles.header}>
                    <h2>{title}</h2>
                    <h3>{subtitle}</h3>
                </div>
                <div className={styles.text}>
                    {children}
                </div>
            </div>
        </div>
    )
}